import React from "react"
import { Link as BaseLink } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"

function NotFound() {
  return (
    <Layout>
      <Container>
        <Header>Page Not Found :(</Header>
        <Link to="/">Back to home</Link>
      </Container>
    </Layout>
  )
}

export default NotFound

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Header = styled("h1")`
  font-family: Playfair Display;
`

const Link = styled(BaseLink)`
  color: ${({ theme }) => theme.contrast};
  font-family: Playfair Display;
  text-decoration: none;
  margin-bottom: 1rem;
  :hover {
    text-decoration: underline;
  }
`
